import React, { useCallback, useEffect, useState, useMemo } from "react";
import CXLogo from "../utils/cxvector.svg";
import Forest from "../utils/forest.png";
import Gmail from "../utils/email.png";
import WhatsApp from "../utils/whats.png";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-materialize";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { sendEmailAction } from "../redux/actions/email";
import { validatePopup } from "../component/formValidation";
import M from "materialize-css";

const Result = () => {
  const dispatch = useDispatch();
  // Retrieve the result from the Redux store
  const result = useMemo(() => JSON.parse(localStorage.getItem("result")), []);
  const success = useSelector((state) => state.emailSending.emailStatus);
  // console.log("annualCarbonFootprint:", result);

  const isMobile = useMediaQuery({ maxDeviceWidth: 480 });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const [showIcons, setShowIcons] = useState(false);

  const toggleIcons = useCallback(() => {
    setShowIcons((prevShowIcons) => !prevShowIcons);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "+91",
  });
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prevState) => ({ ...prevState, [name]: value })); 
  };

  useEffect(() => {
    const elems = document.querySelectorAll(".modal");
    const instances = M.Modal.init(elems);

    return () => {
      instances.forEach((instance) => instance.destroy());
    };
  }, []);

  // Add an effect to watch for changes in the 'success' variable
  useEffect(() => {
    if (success === 'sent') {
      // Close the modal here
      const modalInstance = M.Modal.getInstance(document.getElementById('plasticModal'));
      if (modalInstance) {
        modalInstance.close();
      }
    }
  }, [success]);

  const generateMailToLink = () => {
    const currentYear = new Date().getFullYear();
    const subject = encodeURIComponent("Check out Plastic Footprint Result!");
    const body = encodeURIComponent(
      `Hi there!\n\nI just calculated Carbon footprint and here are the results:\n\n----------------------\nYour Annual Carbon Footprint (kg CO2 eq): ${result?.annualCarbonFootprint}\n----------------------\n\nCopyright © ${currentYear}\nInfinite Cercle Private Limited\n`
    );
    if (isTabletOrMobileDevice) {
      return `mailto:?subject=${subject}&body=${body}`;
    } else {
      return `https://mail.google.com/mail/u/0/?view=cm&fs=1&su=${subject}&body=${body}`;
    }
  };
  const mailToLink = result ? generateMailToLink() : "";
  const shareMessage = `Check out Carbon Footprint Result! I just calculated Carbon footprint and here are the results:
  Your Annual Carbon Footprint (kg CO2 eq): ${result?.annualCarbonFootprint}
  Copyright © ${new Date().getFullYear()}
  Infinite Cercle Private Limited
  Have a look!`;

  const handleFormSubmit = (event) => {
    event.preventDefault(); 
    const formErrors = validatePopup(formData);
    setErrorMessage(formErrors);

    if (!formErrors.name && !formErrors.email && !formErrors.phoneNumber) {
      dispatch(sendEmailAction(formData, setLoading));
    }
  };

  return (
    <div className="">
      <div className="">
        <img src={Forest} alt="Forest" style={{ width: "100%" }} />
        <img
          src={CXLogo}
          alt="CercleX Logo"
          style={{
            width: 104,
            height: 22,
            position: "absolute",
            top: "3rem",
            left: "3rem",
          }}
        />
      </div>
      <div className="flex justify-center">
        <h1 style={{ fontSize:isTabletOrMobileDevice ? 35 : 45, textAlign: isTabletOrMobileDevice ? "center" : null }} className="">
          Your annual carbon footprint (in kg CO2 eq.)
        </h1>
      </div>
      <div className="flex justify-center">
        <h1
          style={{ fontSize: 45, fontStyle: "italic", color: "#0B9B48" }}
          className=""
        >
          {result?.annualCarbonFootprint}Kg
        </h1>
      </div>

      <div className="flex column center align-center">

<button
    className="btn mt-1 modal-trigger"
    style={{ backgroundColor: "#0B9B48" }}
    data-target="plasticModal"
  >
    GO CARBON NEUTRAL{" "}
    <span
      className="material-symbols-outlined"
      style={{ color: "#FFC727" }}
    >
      arrow_forward
    </span>
  </button>

  

        {/* Modal Popup */}
        <div
          id="plasticModal"
          className="modal"
          style={{ borderRadius: "8px" }}
        >
          <div className="modal-content">
            <span className="modal-close material-symbols-outlined top-right-corner close-container">
              close
            </span>
            <div className="center">
              <h1 style={{ fontWeight: 700, fontSize: 20 }}>JOIN THE CARBON-FREE MOVEMENT</h1>
              <p className="mt-1 bold">TO SHAPE A SUSTAINABLE TOMORROW</p>
              <form
                onSubmit={handleFormSubmit}
                className="flex column"
                style={{ alignItems: "center" }}
              >
                <input
                  type="text"
                  placeholder="Name"
                  className="browser-default input-field input-containerr"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errorMessage.name && <small className="red-text">{errorMessage.name}</small>}
                <input
                  type="email"
                  placeholder="Email"
                  className="browser-default input-field input-containerr"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errorMessage.email && <small className="red-text">{errorMessage.email}</small>}
                <input
                  type="text"
                  placeholder=" Phone Number"
                  className="browser-default input-field phone input-containerr"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
                {errorMessage.phoneNumber && <small className="red-text">{errorMessage.phoneNumber}</small>}
                <button type="submit" className="btn cxcolor btns mt-1">
                {loading ? "loading..." : "SUBMIT"}
                </button>
              </form>
            </div>
          </div>
        </div>

        <button
          className="btn"
          onClick={toggleIcons}
          style={{
            marginTop: "2rem",
            backgroundColor: "#6F2DA8",
            borderRadius: '20px'
          }}
        >
          Share your result
        </button>
        {showIcons && (
          <div className="gap-1 mt-1">
            <a href={mailToLink} target="_blank" rel="noopener noreferrer" className="mr-1">
              <img
                src={Gmail}
                alt="Gmail"
                loading="lazy"
                style={{
                  width: "29px",
                  height: "25px",
                  padding: "3px",
                  borderRadius: "5px",
                }}
                className=""
              />
            </a>
            <a
              href={`https://wa.me/?text=${encodeURIComponent(shareMessage)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={WhatsApp}
                alt="WhatsApp"
                loading="lazy"
                style={{
                  width: "27px",
                  height: "27px",
                  padding: "3px",
                  borderRadius: "5px",
                }}
                className=""
              />
            </a>
          </div>
        )}

        <Link
          to="/"
          style={{ textDecoration: "underline" }}
          className="black-text mt-1 bold"
          onClick={() => localStorage.removeItem("result")}
        >
          Back to Home
        </Link>

        <footer className="bold mt-1" style={{  }}>
          Copyright ©{new Date().getFullYear()} Infinite Cercle Private Limited
        </footer>
      </div>

      {/* <div
        style={{ position: "absolute", bottom: 0 }}
        className="mb-5 flex column center"
      >
        <Button onClick={toggleIcons}>Share Your Result</Button>
      </div> */}
    </div>
  );
};

export default Result;
