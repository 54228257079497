import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../component/DataContext";
import { useMediaQuery } from 'react-responsive';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

const Lifestyle = ({ currentStep, setCurrentStep }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [data, setData] = useContext(DataContext);
    // console.log(data, 'lifestyle');
    const [choice, setChoice] = useState(data.lifestyle.tripFrequency);
    const [showChoices, setShowChoices] = useState(false);
    const [showRoadDistance, setShowRoadDistance] = useState(false);
    const [showFlightDistance, setShowFlightDistance] = useState(false);
    const [showWaterDistance, setShowWaterDistance] = useState(false);
    const [showTrainDistance, setShowTrainDistance] = useState(false);

    const [error, setError] = useState({
      tripFrequency: "",
      modeOfTravel: "",
      distances: {
          road: "",
          train: "",
          flight: "",
          water: ""
      },
      treesPlanted: "",
      treesSurvived: ""
    });

    // Define a key for local storage
    const LOCAL_STORAGE_KEY = "lifestyle";
    // ... Other code ...

    // Function to load state from local storage
    // const loadStateFromLocalStorage = () => {
    //     const savedState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    //     if (savedState) {
    //         setChoice(savedState.choice);
    //         setShowChoices(savedState.showChoices);
    //         setShowRoadDistance(savedState.showRoadDistance);
    //         setShowFlightDistance(savedState.showFlightDistance);
    //         setShowWaterDistance(savedState.showWaterDistance);
    //         setShowTrainDistance(savedState.showTrainDistance);
    //     }
    // };

    // // Function to save state to local storage
    // const saveStateToLocalStorage = () => {
    //     const stateToSave = {
    //         choice,
    //         showChoices,
    //         showRoadDistance,
    //         showFlightDistance,
    //         showWaterDistance,
    //         showTrainDistance,
    //     };
    //     localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateToSave));
    // };

    // useEffect(() => {
    //     loadStateFromLocalStorage();
    // }, []);

    // useEffect(() => {
    //     saveStateToLocalStorage();
    // }, [choice, showChoices, showRoadDistance, showFlightDistance, showWaterDistance, showTrainDistance]);

    // const handleDataChange = (key, value) => {
    //     if (value < 0) return;
    //     setData(prevData => ({
    //       ...prevData,
    //       lifestyle: {
    //         ...prevData.lifestyle,
    //         [key]: parseInt(value)
    //       }
    //     }));
    // };

    const handleDataChangev2 = (key, value) => {
        if (value < 0) return;
        setData(prevData => ({
          ...prevData,
          planting: {
            ...prevData.planting,
            [key]: parseInt(value)
          }
        }));
    };

    const handleModeOfTravel = (key, value) => {
        if (value === 'Road') {
          setShowRoadDistance(!showRoadDistance)
        } else if (value === 'Flight') {
          setShowFlightDistance(!showFlightDistance)
        } else if (value === 'Water') {
          setShowWaterDistance(!showWaterDistance)
        } else if (value === 'Train') {
          setShowTrainDistance(!showTrainDistance)
        };
    };

    const handleButtonClick = (key, updatedValue, data, setData) => {
      // Create a copy of the existing data
      const newData = { ...data };
    
      // Update the specified key in the lifestyle object
      newData.lifestyle = {
        ...newData.lifestyle, // Preserve existing properties
        [key]: updatedValue, // Update the specified key with the updatedValue
      };
    
      // Set the updated data in the context
      setData(newData);
    };

    const handleCheckboxChange = (key, value) => {
      setData(prevData => {
        let updatedValue;
    
        // Check if the key already exists in the data
        if (prevData.lifestyle[key]) {
          if (prevData.lifestyle[key].includes(value)) {
            // Remove the value from the array
            updatedValue = prevData.lifestyle[key].filter(item => item !== value);
          } else {
            // Add the value to the array
            updatedValue = [...prevData.lifestyle[key], value];
          }
        } else {
          updatedValue = [value];
        }
    
        return {
          ...prevData,
          lifestyle: {
            ...prevData.lifestyle,
            [key]: updatedValue
          }
        };
      });
    };

    const updateTravelDistance = (key, distance, data, setData) => {
      // Create a copy of the existing data
      const newData = { ...data };
    
      // Update the specified modeOfTravel distance in the lifestyle object
      newData.lifestyle.distances = {
        ...newData.lifestyle.distances,
        [key]: parseInt(distance),
      };
    
      // Set the updated data in the context
      setData(newData);
    }

    const handleValidation = () =>{
      const newErrors = { ...error };

      if (data.lifestyle.tripFrequency === undefined) {
        newErrors.tripFrequency = "Select any trip choice";
      } else {
        newErrors.tripFrequency = ""; // Clear the error message if valid
      }

      if (data.lifestyle.modeOfTravel === undefined) {
        newErrors.modeOfTravel = "Select any one mode of travel at least and fill it's field";
      } else {
        newErrors.modeOfTravel = ""; // Clear the error message if valid
      }

      if (data.planting.treesPlanted === undefined) {
        newErrors.treesPlanted = "This field is required";
      } else {
        newErrors.treesPlanted = ""; // Clear the error message if valid
      }

      if (data.planting.treesSurvived === undefined) {
        newErrors.treesSurvived = "This field is required";
      } else {
        newErrors.treesSurvived = ""; // Clear the error message if valid
      }
    // Set the new errors object in state
    setError(newErrors);
    };

    const validate = data.lifestyle.tripFrequency === undefined || data.lifestyle.modeOfTravel === undefined || 
    data.planting.treesPlanted === undefined || data.planting.treesSurvived === undefined;

    const handleNextClick = () => {
        // setCurrentStep(currentStep + 1);
        handleValidation();
        if (!validate) {
          setCurrentStep(currentStep + 1);
        } else {
          toast.error('Fill all the fields to proceed further');
        }
      };
    
  return (
    <div className="mt-4">
    <div>
    <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
      1. How often do you go for a trip (Business/​Holiday)?
      <span className="mandatory-field">*</span>
    </h3>

    <button 
      onClick={()=>{setShowChoices(!showChoices)}} 
      className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"}
    >
      <p style={{ color: choice === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice}</p>
      <span class="material-symbols-outlined">stat_minus_1</span>
    </button >

    {showChoices ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"}>
          {[
            { value: "Never", text: "Never" },
            { value: "Weekly", text: "Weekly" },
            { value: "Monthly", text: "Monthly" },
            { value: "Yearly", text: "Yearly" },
          ].map((option, index) => (
            <p key={index} onClick={()=>{
              handleButtonClick("tripFrequency", option.value, data, setData); setChoice(option?.value); setShowChoices(false)}} >{option?.text}</p>
          ))}
    </div> : null}
            <p className="red-text">{error?.tripFrequency}</p>
    
    <div className={isMobile ? 'mb-4 mt-2' : "mt-2"}>
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
        2. What is your mode of travel?
        <span className="mandatory-field">*</span>
        </h3>
        <div className="mt-1 flex gap-2">
          <div className={showRoadDistance ? "buttonsv1-selected-container" : "buttonsv1-container"}>
          <button
            className="btn black-text transparent z-depth-0"
            onClick={() => {handleCheckboxChange("modeOfTravel", "Road"); 
            handleModeOfTravel("modeOfTravel", "Road");}}
          >
            🛣️ Road
          </button>
          </div>
          <div className={showFlightDistance ? "buttonsv1-selected-container" : "buttonsv1-container"}>
          <button
            className="btn black-text transparent z-depth-0"
            onClick={() => {handleCheckboxChange("modeOfTravel", "Flight"); 
            handleModeOfTravel("modeOfTravel", "Flight");}}
          >
            🛩️ Flight
          </button>
          </div>
          <div className={showWaterDistance ? "buttonsv1-selected-container" : "buttonsv1-container"}>
          <button
            className="btn black-text transparent z-depth-0"
            onClick={() => {handleCheckboxChange("modeOfTravel", "Water"); 
            handleModeOfTravel("modeOfTravel", "Water");}}
          >
            🛥️ Water
          </button>
          </div>
          <div className={showTrainDistance ? "buttonsv1-selected-container" : "buttonsv1-container"}>
          <button
            className="btn black-text transparent z-depth-0"
            onClick={() => {handleCheckboxChange("modeOfTravel", "Train"); 
            handleModeOfTravel("modeOfTravel", "Train");}}
          >
            🚉 Train
          </button>
          </div>
        </div>
            <p className="red-text">{error?.modeOfTravel}</p>
    </div>

    {showRoadDistance ? <div className="mt-2">
      <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
      Distance travelled by Road. (Km)
        <span className="mandatory-field">*</span>
      </h3>
      <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
        <input
          type="number"
          value={data?.lifestyle?.distances?.road}
          onChange={(e) => updateTravelDistance("road", e.target.value, data, setData)
          }
        />
      </div>
    </div> : null}

    {showFlightDistance ? <div className="mt-2">
      <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
      Distance travelled by Flight. (Km)
        <span className="mandatory-field">*</span>
      </h3>
      <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
        <input
          type="number"
          value={data?.lifestyle?.distances?.flight}
          onChange={(e) =>
            updateTravelDistance("flight", e.target.value, data, setData)
          }
        />
      </div>
    </div> : null}

    {showWaterDistance ? <div className="mt-2">
      <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
      Distance travelled by Water. (Km)
        <span className="mandatory-field">*</span>
      </h3>
      <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
        <input
          type="number"
          value={data?.lifestyle?.distances?.water}
          onChange={(e) =>
            updateTravelDistance("water", e.target.value, data, setData)
          }
        />
      </div>
    </div> : null}

    {showTrainDistance ? <div className="mt-2">
      <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
      Distance travelled by Train. (Km)
        <span className="mandatory-field">*</span>
      </h3>
      <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
        <input
          type="number"
          value={data?.lifestyle?.distances?.train}
          onChange={(e) =>
            updateTravelDistance("train", e.target.value, data, setData)
          }
        />
      </div>
    </div> : null}

    <div className={"mt-2"}>
      <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
      3. How many trees have you planted before?
        <span className="mandatory-field">*</span>
      </h3>
      <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
        <input
          type="number"
          value={data.planting.treesPlanted}
          onChange={(e) =>
            handleDataChangev2("treesPlanted", e.target.value)
          }
        />
      </div>
            <p className="red-text">{error?.treesPlanted}</p>
    </div>

    <div className="mt-2">
      <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
      4. How many from that have survived?
        <span className="mandatory-field">*</span>
      </h3>
      <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
        <input
          type="number"
          value={data.planting.treesSurvived}
          onChange={(e) =>
            handleDataChangev2("treesSurvived", e.target.value)
          }
        />
      </div>
            <p className="red-text">{error?.treesSurvived}</p>
    </div>

  </div>
    <div className="center mb-3 mt-2">
      <button 
        className="buttonsv2-container"
        onClick={handleNextClick}
      >
        Next
        <span class="material-symbols-outlined arrow-icon-next">arrow_right_alt</span>
      </button>
    </div>
  </div>
  )
}

export default Lifestyle