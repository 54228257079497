import React, { useState } from 'react';
import Frame from '../layout/Frame';
import Household from '../pages/Household';
import Lifestyle from '../pages/Lifestyle';
import Food from '../pages/Food';
import Travel from '../pages/Travel';
import Waste from '../pages/Waste';
import { DataProvider } from '../component/DataContext';
import Result from './Result';

function FormContainer() {
  const [currentStep, setCurrentStep] = useState(1);

  const renderFormPage = () => {
    switch (currentStep) {
      case 1:
        return (
          <DataProvider currentStep={currentStep} setCurrentStep={setCurrentStep}>
             <Household />
          </DataProvider>
        );
      case 2:
        return (
          <DataProvider currentStep={currentStep} setCurrentStep={setCurrentStep}>
             <Lifestyle />
          </DataProvider>
        );
      case 3:
        return (
          <DataProvider currentStep={currentStep} setCurrentStep={setCurrentStep}>
             <Food />
          </DataProvider>
        );
      case 4:
        return (
          <DataProvider currentStep={currentStep} setCurrentStep={setCurrentStep}>
             <Travel />
          </DataProvider>
        );
      case 5:
        return (
          <DataProvider currentStep={currentStep} setCurrentStep={setCurrentStep}>
             <Waste />
          </DataProvider>
        );
      default:
        return <Household currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
    }
  };

  return (
    <div>
      <Frame currentStep={currentStep} setCurrentStep={setCurrentStep}>
      {renderFormPage()}
      </Frame>
    </div>
  );
}

export default FormContainer;
