import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../component/DataContext";
import { useMediaQuery } from "react-responsive";
import Terraced from "../utils/terraced.svg";
import Flat from "../utils/flat.svg";
import Detached from "../utils/detached.svg";
import SemiDettached from "../utils/semi-detached.svg";
import Bungalow from "../utils/bungalow.svg";
import EcoFriendly from "../utils/eco_friendly.svg";
import Conventional from "../utils/conventional.svg";
import Kerosene from "../utils/kerosine.svg";
import Coconut from "../utils/coconut.svg";
import LPG from "../utils/lpg.svg";
import Wood from "../utils/wood.svg";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';


const stateNames = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Dadara & Nagar Havelli & Daman & Diu",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman & Nicobar Island",
  "Chandigarh",
  "Jammu & Kashmir",
  "Puducherry",
  "Delhi"
];

const Household = ({ currentStep, setCurrentStep }) => {
  const [data, setData] = useContext(DataContext);
  // console.log(data, 'household');
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [energyEfficientAppliances, setEnergyEfficientAppliances] = useState(data.household.energyEfficientAppliances);
  const [cylinderType, setCylinderType] = useState(data.household.lpgCylinderType);
  
  const [selectedHouse, setSelectedHouse] = useState(data.household.housingType);

  const [choice, setChoice] = useState(data?.location);
  const [showChoices, setShowChoices] = useState(false);

  const [selectedFuels, setSelectedFuels] = useState(data.household.fuels);

  const [showComponent1, setShowComponent1] = useState(selectedFuels?.includes("Kerosene"));
  const [showComponent2, setShowComponent2] = useState(selectedFuels?.includes("Coconut Shell"));
  const [showComponent3, setShowComponent3] = useState(selectedFuels?.includes("LPG"));
  const [showComponent4, setShowComponent4] = useState(selectedFuels?.includes("Wood"));

  const [error, setError] = useState({
    location: "",
    people: "",
    biMonthlyElectricity: "",
    housingType: "",
    energyEfficientAppliances: "",
    renewableEnergyPercentage: "",
    ecoFriendly: "",
    homeSize: "",
    fuels: "",
    kerosene: "",
    wood: "",
    coconutShell: "",
    lpgCylinderType: "",
    lpgCylindersPerYear: "" 
});

// console.log(data?.household?.fuels);

  const handleDataChange = (key, value) => {
    if (value < 0) return;
    setData((prevData) => ({
      ...prevData,
      household: {
        ...prevData.household,
        [key]: parseInt(value),
      },
    }));

    // switch (key) {
    //   case 'people':
    //     dispatch(setPeople(parseInt(value)));
    //     break;
    //   case 'housingType':
    //     dispatch(setHousingType(value));
    //     break;
    //   case 'biMonthlyElectricity':
    //     dispatch(setBiMonthlyElectricity(value));
    //     break;
    //   case 'ecoFriendly':
    //     dispatch(setEcoFriendly(value));
    //     break;
    //   case 'homeSize':
    //     dispatch(setHomeSize(value));
    //     break;
    //   case 'kerosene':
    //     dispatch(setKerosene(parseInt(value)));
    //     break;
    //   case 'coconutShell':
    //     dispatch(setCoconutShell(parseInt(value)));
    //     break;
    //   case 'lpgCylindersPerYear':
    //     dispatch(setLpgCylindersPerYear(parseInt(value)));
    //     break;
    //   default:
    //     break;
    // }
  };

  const handleDataChangeV2 = (key, value) => {
    if (value < 0) return;
    setData((prevData) => ({
      ...prevData,
      household: {
        ...prevData.household,
        [key]: value,
      },
    }));
  };

  const handleEnergy = (value) => {
    setEnergyEfficientAppliances(value);

    // Dispatch the selected housing type to Redux
    // dispatch(setEnergyEfficientAppliances(value));
  };

  const handleCylinderType = (value) => {
    setCylinderType(value);

    // Dispatch the selected housing type to Redux
    // dispatch(setLpgCylinderType(value));
  };

  const handleHouseSelection = (houseValue) => {
    // Update the selected house when a button is clicked
    setSelectedHouse(houseValue);
    // You can also call your handleDataChange function here if needed
    // handleDataChange("housingType", houseValue);

    // Dispatch the selected housing type to Redux
    // dispatch(setHousingType(houseValue));
  };

  // const handleFuelTypes = (value) => {
  //   setFuelTypes(value);
  // };

  const handleFuelTypes = (value) =>{
    if (value === 'Kerosene') {
      setShowComponent1(!showComponent1)
    } else if (value === 'Coconut Shell') {
      setShowComponent2(!showComponent2)
    } else if (value === 'LPG') {
      setShowComponent3(!showComponent3)
    } else if (value === 'Wood') {
      setShowComponent4(!showComponent4)
    };
  };

  const handleCheckboxChange = (key, value) => {
    setData(prevData => {
      let updatedValue;
  
      // Check if the key already exists in the data
      if (prevData.household[key]) {
        if (prevData.household[key].includes(value)) {
          // Remove the value from the array
          updatedValue = prevData.household[key].filter(item => item !== value);
        } else {
          // Add the value to the array
          updatedValue = [...prevData.household[key], value];
        }
      } else {
        updatedValue = [value];
      }
  
      return {
        ...prevData,
        household: {
          ...prevData.household,
          [key]: updatedValue
        }
      };
    });

    // Dispatch the action to update the Redux state
    // dispatch(setFuels(key, value));
  };

  const handleCheckboxChangev2 = (key, value) => {
    setData((prevData) => {
      let updatedValue;

      // Check if the key already exists in the data
      if (prevData.household[key]) {
        if (prevData.household[key].includes(value)) {
          // Remove the value from the array
          updatedValue = prevData.household[key].filter((item) => item !== value);
        } else {
          // Add the value to the array
          updatedValue = [...prevData.household[key], value];
        }
      } else {
        updatedValue = [value];
      }

      // Update the selectedFuels state
      setSelectedFuels(updatedValue);

      return {
        ...prevData,
        household: {
          ...prevData.household,
          [key]: updatedValue,
        },
      };
    });
  };

  const handleHouseButtonClick = (key, updatedValue, data, setData) => {
    // Create a copy of the existing data
    const newData = { ...data };
  
    // Update the specified key in the household object
    newData.household = {
      ...newData.household, // Preserve existing properties
      [key]: updatedValue, // Update the specified key with the updatedValue
    };
  
    // Set the updated data in the context
    setData(newData);
  };

  // Use useEffect to update the component visibility when selectedFuels changes
  useEffect(() => {
    setShowComponent1(selectedFuels?.includes("Kerosene"));
    setShowComponent2(selectedFuels?.includes("Coconut Shell"));
    setShowComponent3(selectedFuels?.includes("LPG"));
    setShowComponent4(selectedFuels?.includes("Wood"));
  }, [selectedFuels]);

  const updateLocation = (newLocation) => {
    setData((prevData) => ({
      ...prevData,
      location: newLocation,
    }));
  };
  
  // console.log(data.household.fuels);

  // State to manage the selected eco-friendly option
  const [selectedEcoFriendlyOption, setSelectedEcoFriendlyOption] = useState(
    localStorage.getItem("household") || ""
  );

  // Handle changes to the eco-friendly option
  const handleEcoFriendlyOptionChange = (value) => {
    setSelectedEcoFriendlyOption(value);
    localStorage.setItem("selectedEcoFriendlyOption", value);
    handleDataChangeV2("ecoFriendly", value);
  };

  const validateHousehold = () => {
    const newErrors = { ...error };

    if (data.location === undefined) {
      newErrors.location = "Select any location before submitting";
    } else {
      newErrors.location = ""; // Clear the error message if valid
    }

    if (data.household.people < 1) {
      newErrors.people = "Number of people must be at least 1";
    } else {
      newErrors.people = ""; // Clear the error message if valid
    }

    if (selectedHouse === undefined) {
      newErrors.housingType = "Select housing type";
    } else {
      newErrors.housingType = ""; // Clear the error message if valid
    }

    if (data.household.biMonthlyElectricity === undefined) {
      newErrors.biMonthlyElectricity = "This field is required";
    }
    else if (data.household.biMonthlyElectricity < 1) {
      newErrors.biMonthlyElectricity = "This field is required";
    } else {
      newErrors.biMonthlyElectricity = ""; // Clear the error message if valid
    }

    if (energyEfficientAppliances === undefined) {
      newErrors.energyEfficientAppliances = "Select an option";
    } else {
      newErrors.energyEfficientAppliances = ""; // Clear the error message if valid
    }

    if (data.household.renewableEnergyPercentage < 1) {
      newErrors.renewableEnergyPercentage = "Electricity percentage cannot be 0";
    } else {
      newErrors.renewableEnergyPercentage = ""; // Clear the error message if valid
    }

    if (data.household.ecoFriendly === undefined) {
      newErrors.ecoFriendly = "Select an option";
    } else {
      newErrors.ecoFriendly = ""; // Clear the error message if valid
    }

    if (data.household.homeSize === undefined) {
      newErrors.homeSize = "This field is required";
    }
    else if (data.household.homeSize < 1) {
      newErrors.homeSize = "This field is required";
    } else {
      newErrors.homeSize = ""; // Clear the error message if valid
    }

    if (data.household.fuels === undefined) {
      newErrors.fuels = "Select any one at least";
    } else {
      newErrors.fuels = ""; // Clear the error message if valid
    }
    
    if (cylinderType === undefined) {
      newErrors.lpgCylinderType = "Select an option";
    } else {
      newErrors.lpgCylinderType = ""; // Clear the error message if valid
    }
    // Set the new errors object in state
    setError(newErrors);
  };

  const validate = choice === '' || data.household.people < 1 || selectedHouse === undefined || 
  data.household.biMonthlyElectricity === undefined || energyEfficientAppliances === undefined || 
  data.household.renewableEnergyPercentage < 1 || data.household.ecoFriendly === undefined ||
  data.household.homeSize === undefined || data.household.fuels === undefined || cylinderType === undefined;

  const handleNextClick = () => {
    // setCurrentStep(currentStep + 1);
    validateHousehold();
    if (!validate) {
      setCurrentStep(currentStep + 1);
    } else {
      toast.error('Fill all the fields to proceed further');
    }
  };

  return (
    <div className="" style={{  }}>

    <div className={"mt-2"}>
        <div className="mr-5">
          <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            1. Which state are you living in?
            <span className="mandatory-field">*</span>
          </h3>

          <button 
            onClick={()=>{setShowChoices(!showChoices)}} 
            className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"} 
            style={{  }}
          >
            <p style={{ color: 'black' }}>{choice ? choice : 'Select the Choices'}</p>
            <span class="material-symbols-outlined">stat_minus_1</span>
          </button >

          {showChoices ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"} style={{ overflowX: 'auto', height: '175px' }}>
            {stateNames.map((location, index) => (
              <p key={index} onClick={()=>{
                updateLocation(location); setChoice(location); setShowChoices(false)}} >{location}</p>
            ))}
          </div> : null}
        </div>
            <p className="red-text">{error?.location}</p>
      </div>
      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          2. How many people live in your household?
            <span className="mandatory-field">*</span>
        </h3>

        <div
          className="valign-wrapper mt-1 counter-buttons-container"
          style={{
            width: isMobile ? "50vw" : "10vw",
          }}
        >
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("people", data.household.people - 1)
            }
          >
            <span className="material-symbols-outlined black-text">remove</span>
          </button>
          <span
            className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
          >
            {data.household.people}
          </span>
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("people", data.household.people + 1)
            }
          >
            <span className="material-symbols-outlined black-text">add</span>
          </button>
        </div>
            <p className="red-text">{error?.people}</p>
      </div>

      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          3. Which best describes your housing type?
            <span className="mandatory-field">*</span>
        </h3>

        <div className={"flex mt-1 gap-2"} style={{ overflow: isMobile ? 'scroll' : null }}>
          {[
            { src: Terraced, value: "Terraced", alt: "Terraced House" },
            { src: Flat, value: "Flat", alt: "Flat" },
            { src: Detached, value: "Detached", alt: "Detached House" },
            {
              src: SemiDettached,
              value: "Semi-detached",
              alt: "Semi-Dettached House",
            },
            { src: Bungalow, value: "Bungalow", alt: "Bungalow" },
          ].map((house, index) => (
            // <label style={{  }} key={index} className={isMobile ? "house-label-ismobile mr-1" : "house-label-v2"}>
            //   <input
            //     className="radio-container"
            //     type="radio"
            //     name="housingType"
            //     value={house.value}
            //     onChange={(e) =>
            //       handleDataChange("housingType", e.target.value)
            //     }
            //     withGap
            //   />
            //   {/* <img src={house.src} alt={house.alt} className={isMobile ? "house-image-ismobile" : "house-image"}  /> */}
            //   <div className="black-text bold center mt-1">{house.value}</div>
            // </label>
              <div className={selectedHouse === house.value ? "buttonsv1-selected-container" : "buttonsv1-container"}>
                <button
                  className="btn black-text transparent z-depth-0"
                  name="housingType"
                  value={house.value}
                  // onClick={() => handleHouseSelection(house.value)}
                  // onClick={(e) => handleDataChange("housingType", e.target.value)}
                  // onChange={(e) =>
                  //   handleDataChange("housingType", e.target.value)
                  // }
                  onClick={() => {handleHouseButtonClick("housingType", house.value, data, setData); handleHouseSelection(house.value)}}
                  style={{ whiteSpace: 'nowrap' }}
                >{house.value}
                </button>
              </div>
          ))}
        </div>
            <p className="red-text">{error?.housingType}</p>
      </div>
      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          4. What is your Bi-monthly Electricity Consumption (in Units/​KWh) ?
            <span className="mandatory-field">*</span>
        </h3>
        <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
          <input
            className="input-border"
            type="number"
            placeholder="Enter units/kWh"
            onChange={(e) =>
              handleDataChange("biMonthlyElectricity", e.target.value)
            }
            value={data.household.biMonthlyElectricity}
          />
        </div>
            <p className="red-text">{error?.biMonthlyElectricity}</p>
      </div>
      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          5. Do you use energy efficient appliances? (LED bulbs, 5-Star rating
          appliances etc.,)
            <span className="mandatory-field">*</span>
        </h3>
        <div className="mt-1 flex gap-2">
          {[
            { value: "Yes", text: "👍Yes" },
            { value: "No", text: "👎No" },
          ].map((option, index) => (
            <div
              key={index}
              className={
                energyEfficientAppliances === option.value
                  ? "buttonsv1-selected-container"
                  : "buttonsv1-container"
              }
            >
              <button
                className="btn black-text transparent z-depth-0"
                onClick={() =>{
                  handleHouseButtonClick("energyEfficientAppliances", option.value, data, setData);
                  handleEnergy(option.value)
                }}
              >
                {option.text}
              </button>
            </div>
          ))}
        </div>
            <p className="red-text">{error?.energyEfficientAppliances}</p>
      </div>

      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          6. What % of your electricity comes from renewable resources (E.g. Solar, Wind)
            <span className="mandatory-field">*</span>
        </h3>

        <div
          className="valign-wrapper mt-1 counter-buttons-container"
          style={{
            width: isMobile ? "80vw" : "10vw",
          }}
        >
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("renewableEnergyPercentage", data.household.renewableEnergyPercentage - 1)
            }
          >
            <span className="material-symbols-outlined black-text">remove</span>
          </button>
          <span
            className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
          >
            {data.household.renewableEnergyPercentage}
          </span>
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("renewableEnergyPercentage", data.household.renewableEnergyPercentage + 1)
            }
          >
            <span className="material-symbols-outlined black-text">add</span>
          </button>
        </div>
            <p className="red-text">{error?.renewableEnergyPercentage}</p>
      </div>

      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          7. Is your house Eco-friendly or conventional?
            <span className="mandatory-field">*</span>
        </h3>
        <div className="flex gap-2 mt-1" style={{ overflow: isMobile ? 'scroll' : null }}>
          {[
            {
              src: EcoFriendly,
              value: "Eco-friendly",
              alt: "Eco-Friendly",
              discription: "(Bamboo/​Wood/​Straw)",
            },
            {
              src: Conventional,
              value: "Conventional",
              alt: "Conventional",
              discription: "(Brick/​Concrete/​Steel)",
            },
          ].map((house, index) => (
            <label key={index} className={isMobile ? "house-label-ismobile" : "house-label"}>
              <input
                type="radio"
                name="ecoFriendly"
                value={house.value}
                checked={data?.household?.ecoFriendly === house.value}
                onChange={(e) =>
                  handleDataChangeV2("ecoFriendly", e.target.value)
                }
                withGap
              />
              <img src={house.src} alt={house.alt} className="house-image" />
              <div className="black-text bold center mt-1">{house.value}</div>
              <div className="black-text bold center">{house.discription}</div>
            </label>
          ))}
        </div>
            <p className="red-text">{error?.ecoFriendly}</p>
      </div>

      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          8. What is the size of your home? (Sq. ft.)
            <span className="mandatory-field">*</span>
        </h3>
        <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
          <input
            type="number"
            placeholder="Enter Sq. Ft."
            onChange={(e) => handleDataChange("homeSize", e.target.value)}
            value={data.household.homeSize}
          />
        </div>
            <p className="red-text">{error?.homeSize}</p>
      </div>

      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
        9. What are the fuels you use for heating and cooking? (Select all the options that apply)
            <span className="mandatory-field">*</span>
        </h3>

        <div style={{  overflow: isMobile ? 'scroll' : null }} className={isMobile ? "flex mt-2" : "flex mt-2 gap-2"} >
          {[
            { src: Kerosene, value: "Kerosene", alt: "Kerosene" },
            { src: Coconut, value: "Coconut Shell", alt: "Coconut Shell" },
            { src: LPG, value: "LPG", alt: "LPG" },
            { src: Wood, value: "Wood", alt: "Wood" },
          ].map((house, index) => (
            <label key={index} className={isMobile ? "house-label-ismobile mr-2" : "house-label"}>
            <input
                type="checkbox"
                name="fuels"
                value={house.value}
                onChange={(e) => {handleCheckboxChangev2("fuels", e.target.value); handleFuelTypes(house?.value)}}
                checked={selectedFuels?.includes(house.value)}  
              />
              <div className="custom-checkbox"></div>
              <img src={house.src} alt={house.alt} className="house-image" />
              <div className="black-text bold center mt-1">{house.value}</div>
            </label>
          ))}
        </div>
            <p className="red-text">{error?.fuels}</p>
      </div>

      {showComponent1 && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          I. How many litres of kerosene you use per month? (litres/month)
            <span className="mandatory-field">*</span>
        </h3>

        <div
          className="valign-wrapper mt-1 counter-buttons-container"
          style={{
            width: isMobile ? "80vw" : "10vw",
          }}
        >
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("kerosene", data.household.kerosene - 1)
            }
          >
            <span className="material-symbols-outlined black-text">remove</span>
          </button>
          <span
            className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
          >
            {data.household.kerosene}
          </span>
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("kerosene", data.household.kerosene + 1)
            }
          >
            <span className="material-symbols-outlined black-text">add</span>
          </button>
        </div>
      </div> }

      {showComponent2 && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          II. How many kilograms of coconut shell you use per month? (kg/month)
            <span className="mandatory-field">*</span>
        </h3>

        <div
          className="valign-wrapper mt-1 counter-buttons-container"
          style={{
            width: isMobile ? "80vw" : "10vw",
          }}
        >
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("coconutShell", data.household.coconutShell - 1)
            }
          >
            <span className="material-symbols-outlined black-text">remove</span>
          </button>
          <span
            className="center-align"
            style={{ flexGrow: 1, fontSize: isMobile ? "16px" : "18px" }}
          >
            {data.household.coconutShell}
          </span>
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("coconutShell", data.household.coconutShell + 1)
            }
          >
            <span className="material-symbols-outlined black-text">add</span>
          </button>
        </div>
      </div> }

      {showComponent3 && (<div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          III. No Of Cylinders You Use Per Year?
            <span className="mandatory-field">*</span>
        </h3>

        <div
          className="valign-wrapper mt-1 counter-buttons-container"
          style={{
            width: isMobile ? "80vw" : "10vw",
          }}
        >
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange(
                "lpgCylindersPerYear",
                data.household.lpgCylindersPerYear - 1
              )
            }
          >
            <span className="material-symbols-outlined black-text">remove</span>
          </button>
          <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
          >
            {data.household.lpgCylindersPerYear}
          </span>
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange(
                "lpgCylindersPerYear",
                data.household.lpgCylindersPerYear + 1
              )
            }
          >
            <span className="material-symbols-outlined black-text">add</span>
          </button>
        </div>
      </div> )}

      {showComponent4 && (<div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          IV. How many kilograms of wood you use per month? (kg/month)
            <span className="mandatory-field">*</span>
        </h3>

        <div
          className="valign-wrapper mt-1 counter-buttons-container"
          style={{
            width: isMobile ? "80vw" : "10vw",
          }}
        >
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("wood", data.household.wood - 1)
            }
          >
            <span className="material-symbols-outlined black-text">remove</span>
          </button>
          <span
            className="center-align"
            style={{ flexGrow: 1, fontSize: isMobile ? "16px" : "18px" }}
          >
            {data.household.wood}
          </span>
          <button
            className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
            onClick={() =>
              handleDataChange("wood", data.household.wood + 1)
            }
          >
            <span className="material-symbols-outlined black-text">add</span>
          </button>
        </div>
      </div> ) }

      <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          10. What is your LPG cylinder type?
            <span className="mandatory-field">*</span>
        </h3>
        <div className="mt-1 flex gap-2">
          {[
            { value: "14.2 kg", text: "14.2 kg" },
            { value: "5 kg", text: "5 kg" },
          ].map((option, index) => (
            <div
              key={index}
              className={
                cylinderType === option.value
                  ? "buttonsv1-selected-container"
                  : "buttonsv1-container"
              }
            >
              <button
                className="btn black-text transparent z-depth-0"
                onClick={() =>{
                  handleHouseButtonClick("lpgCylinderType", option.value, data, setData);
                  handleCylinderType(option.value)
                }}
              >
                {option.text}
              </button>
            </div>
          ))}
        </div>
            <p className="red-text">{error?.lpgCylinderType}</p>
      </div>

      <div className="center mb-3 mt-2">
        <button
          className="buttonsv2-container"
          onClick={handleNextClick}
        >
          Next
        <span class="material-symbols-outlined arrow-icon-next">arrow_right_alt</span>
        </button>
      </div>
    </div>
  );
};

export default Household;
