import React, { useContext, useState } from "react";
import { DataContext } from "../component/DataContext";
import { useMediaQuery } from 'react-responsive';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

const Travel = ({ currentStep, setCurrentStep }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [data, setData] = useContext(DataContext);
    // console.log(data, 'travel');

  const [electricVehicles, setElectricVehicles] = useState(data?.travel?.electricVehicles);
  const [carFuel, setCarFuel] = useState(data?.travel?.car?.fuel);
  const [flightTravel, setFlightTravel] = useState(null);

  const [error, setError] = useState({
    electricVehicle: "",
    motorcycle: {
        dailyDistance: "",
        mileage: ""
    },
    car: {
        dailyDistance: "",
        fuel: "",
        mileage: ""
    },
    weeklyDistances: {
        bus: "",
        auto: "",
        taxi: "",
        train: "",
        metro: ""
    },
    airTravel: {
        frequency: "",
        yearlyDistance: ""
    }
});

  const handleNextClick = () => {
        // setCurrentStep(currentStep + 1);
        handleValidation();
        if (!validate) {
            setCurrentStep(currentStep + 1);
        } else {
            toast.error('Fill all the fields to proceed further');
        }
    };

    // const handleDataChange = (key, value) => {
    //     if (value < 0) return;
    //     setData(prevData => ({
    //       ...prevData,
    //       travel: {
    //         ...prevData.travel,
    //         [key]: parseInt(value)
    //       }
    //     }));
    // };

    const handleDataChange = (vehicle, property, value) => {
        if (value < 0) return;
        setData((prevData) => ({
          ...prevData,
          travel: {
            ...prevData.travel,
            [vehicle]: {
              ...prevData.travel[vehicle], // Preserve the existing properties in the nested object
              [property]: parseInt(value), // Update the specific property
            },
          },
        }));
    };

    const handleElectricVehicles = (value) => {
      setElectricVehicles(value);
    };

    const handleCarFuel = (value) => {
      setCarFuel(value);
    };

    const handleFlightTravel = (value) => {
      setFlightTravel(value);
    };

    const handleHouseButtonClick = (key, updatedValue, data, setData) => {
      // Create a copy of the existing data
      const newData = { ...data };
    
      // Update the specified key in the travel object
      newData.travel = {
        ...newData.travel, // Preserve existing properties
        [key]: updatedValue, // Update the specified key with the updatedValue
      };
    
      // Set the updated data in the context
      setData(newData);
    };

    const handleButtonClickV2 = (modeOfTravel, key, distance, data, setData) => {
        // Create a copy of the existing data
        const newData = { ...data };
        
        // Update the specified modeOfTravel distance in the travel object
        newData.travel[modeOfTravel] = {
            ...newData.travel[modeOfTravel],
            [key]: distance,
        };
        
        // Set the updated data in the context
        setData(newData);
    };

    const handleValidation = () =>{
        const newErrors = { ...error };

      if (data.travel.electricVehicle === undefined) {
        newErrors.electricVehicle = "Select any one option";
      } else {
        newErrors.electricVehicle = ""; // Clear the error message if valid
      }
      if (data.travel.motorcycle.dailyDistance < 1) {
        newErrors.motorcycle.dailyDistance = "Kilometres must be at least 1";
      } else {
        newErrors.motorcycle.dailyDistance = ""; // Clear the error message if valid
      }
      if (data.travel.motorcycle.mileage < 1) {
        newErrors.motorcycle.mileage = "Kilometres must be at least 1";
      } else {
        newErrors.motorcycle.mileage = ""; // Clear the error message if valid
      } 
      if (data.travel.car.dailyDistance < 1) {
        newErrors.car.dailyDistance = "Kilometres must be at least 1";
      } else {
        newErrors.car.dailyDistance = ""; // Clear the error message if valid
      } 
      if (data.travel.car.fuel === undefined) {
        newErrors.car.fuel = "Select any one option";
      } else {
        newErrors.car.fuel = ""; // Clear the error message if valid
      }
      if (data.travel.car.mileage < 1) {
        newErrors.car.mileage = "Kilometres must be at least 1";
      } else {
        newErrors.car.mileage = ""; // Clear the error message if valid
      }  
      if (data.travel.weeklyDistances.bus < 1) {
        newErrors.weeklyDistances.bus = "Kilometres must be at least 1";
      } else {
        newErrors.weeklyDistances.bus = ""; // Clear the error message if valid
      } 
      if (data.travel.weeklyDistances.auto < 1) {
        newErrors.weeklyDistances.auto = "Kilometres must be at least 1";
      } else {
        newErrors.weeklyDistances.auto = ""; // Clear the error message if valid
      } 
      if (data.travel.weeklyDistances.taxi < 1) {
        newErrors.weeklyDistances.taxi = "Kilometres must be at least 1";
      } else {
        newErrors.weeklyDistances.taxi = ""; // Clear the error message if valid
      } 
      if (data.travel.weeklyDistances.train < 1) {
        newErrors.weeklyDistances.train = "Kilometres must be at least 1";
      } else {
        newErrors.weeklyDistances.train = ""; // Clear the error message if valid
      } 
      if (data.travel.weeklyDistances.metro < 1) {
        newErrors.weeklyDistances.metro = "Kilometres must be at least 1";
      } else {
        newErrors.weeklyDistances.metro = ""; // Clear the error message if valid
      } 
      if (data.travel.airTravel.frequency === undefined) {
        newErrors.airTravel.frequency = "Select any one option";
      } else {
        newErrors.airTravel.frequency = ""; // Clear the error message if valid
      } 
      if (data.travel.airTravel.yearlyDistance === 0) {
        newErrors.airTravel.yearlyDistance = "This field is required";
      } else {
        newErrors.airTravel.yearlyDistance = ""; // Clear the error message if valid
      } 
      // Set the new errors object in state
      setError(newErrors);
    };

    const validate = data.travel.electricVehicle === undefined || data.travel.motorcycle.dailyDistance < 1 || data.travel.motorcycle.mileage < 1 ||
    data.travel.car.dailyDistance < 1 || data.travel.car.fuel === undefined || data.travel.car.mileage < 1 || data.travel.weeklyDistances.bus < 1 ||
    data.travel.weeklyDistances.auto < 1 || data.travel.weeklyDistances.taxi < 1 || data.travel.weeklyDistances.train < 1 ||
    data.travel.weeklyDistances.metro < 1 || data.travel.airTravel.frequency === undefined || data.travel.airTravel.yearlyDistance === 0;

  return (
    <div className="mt-4">
        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            1. Do you use Efficient Electric Vehicles?
            <span className="mandatory-field">*</span>
            </h3>
            <div className="mt-1 flex gap-2">
            {[
                { value: "Yes", text: "👍Yes" },
                { value: "No", text: "👎No" },
            ].map((option, index) => (
                <div
                key={index}
                className={
                    electricVehicles === option.value
                    ? "buttonsv1-selected-container"
                    : "buttonsv1-container"
                }
                >
                <button
                    className="btn black-text transparent z-depth-0"
                    onClick={() =>{
                    handleHouseButtonClick("electricVehicle", option.value, data, setData);
                    handleElectricVehicles(option.value)
                    }}
                >
                    {option.text}
                </button>
                </div>
            ))}
            </div>
            <p className="red-text">{error.electricVehicle}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            2. How many kilometers you travel in motorcycle a day? (km/​day)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("motorcycle", "dailyDistance", data.travel.motorcycle.dailyDistance - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.motorcycle.dailyDistance}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("motorcycle", "dailyDistance", data.travel.motorcycle.dailyDistance + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error.motorcycle.dailyDistance}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            3. Mileage of your motorcycle. (km/​l)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("motorcycle", "mileage", data.travel.motorcycle.mileage - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.motorcycle.mileage}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("motorcycle", "mileage", data.travel.motorcycle.mileage + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error.motorcycle.mileage}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            4. How many kilometers you travel in car a day? (km/day)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("car", "dailyDistance", data.travel.car.dailyDistance - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.car.dailyDistance}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("car", "dailyDistance", data.travel.car.dailyDistance + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.car?.dailyDistance}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            5. Car Fuel
            <span className="mandatory-field">*</span>
            </h3>
            <div className="mt-1 flex gap-2">
            {[
                { value: "Petrol", text: "Petrol" },
                { value: "Diesel", text: "Diesel" },
            ].map((option, index) => (
                <div
                key={index}
                className={
                    carFuel === option.value
                    ? "buttonsv1-selected-container"
                    : "buttonsv1-container"
                }
                >
                <button
                    className="btn black-text transparent z-depth-0"
                    onClick={() =>{
                    handleButtonClickV2("car", "fuel", option.value, data, setData);
                    handleCarFuel(option.value)
                    }}
                >
                    {option.text}
                </button>
                </div>
            ))}
            </div>
            <p className="red-text">{error?.car?.fuel}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            6. Mileage of your Car. (km/​l)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("car", "mileage", data.travel.car.mileage - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.car.mileage}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("car", "mileage", data.travel.car.mileage + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.car?.mileage}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            7. How many kilometers you travel by bus per week? (km/​week)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "bus", data.travel.weeklyDistances.bus - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.weeklyDistances.bus}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "bus", data.travel.weeklyDistances.bus + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.weeklyDistances?.bus}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            8. How many kilometers you travel by auto per week? (km/week)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "auto", data.travel.weeklyDistances.auto - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.weeklyDistances.auto}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "auto", data.travel.weeklyDistances.auto + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.weeklyDistances?.auto}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            9. How many kilometers you travel by taxi per week? (km/​week)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "taxi", data.travel.weeklyDistances.taxi - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.weeklyDistances.taxi}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "taxi", data.travel.weeklyDistances.taxi + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.weeklyDistances?.taxi}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            10. How many kilometers you travel by train per week? (km/week)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "train", data.travel.weeklyDistances.train - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.weeklyDistances.train}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "train", data.travel.weeklyDistances.train + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.weeklyDistances?.train}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            11. How many kilometers you travel by metro per week? (km/​week)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "metro", data.travel.weeklyDistances.metro - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.travel.weeklyDistances.metro}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("weeklyDistances", "metro", data.travel.weeklyDistances.metro + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.weeklyDistances?.metro}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            12. How often you travel by flight?
            <span className="mandatory-field">*</span>
            </h3>
            <div className="mt-1 flex gap-2">
            {[
                { value: "Never", text: "Never" },
                { value: "Little", text: "Little" },
                { value: "Frequent", text: "Frequent" },
            ].map((option, index) => (
                <div
                key={index}
                className={
                    flightTravel === option.value
                    ? "buttonsv1-selected-container"
                    : "buttonsv1-container"
                }
                >
                <button
                    className="btn black-text transparent z-depth-0"
                    onClick={() =>{
                    handleButtonClickV2("airTravel", "frequency", option.value, data, setData);
                    handleFlightTravel(option.value)
                    }}
                >
                    {option.text}
                </button>
                </div>
            ))}
            </div>
            <p className="red-text">{error?.airTravel.frequency}</p>
        </div>

        <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
        13. How many kilometers you travel by air per year? (km/year)
            <span className="mandatory-field">*</span>
        </h3>
        <div className={isMobile ? 'input-container-ismobile mt-1' : "input-containerr mt-1"}>
            <input
            type="number"
            onChange={(e) =>
                handleDataChange("airTravel", "yearlyDistance", e.target.value)
            }
            />
        </div>
            <p className="red-text">{error?.airTravel?.yearlyDistance}</p>
        </div>
    
    <div className="center mb-3 mt-2">
        <button 
        className="buttonsv2-container"
        onClick={handleNextClick}
        >
        Next
        <span class="material-symbols-outlined arrow-icon-next">arrow_right_alt</span>
        </button>
    </div>

    </div>
  );
};

export default Travel;