import axios from "axios";
import { authenticationService, planetWarriorService } from "./config";

function getAuthUrl() {
  return `${authenticationService.baseURL}`;
}

function getAuth() {
  let Auth = axios.create(authenticationService);
  const token = localStorage.getItem("CCXT_PWC");
  if (token) Auth.defaults.headers.common["X-Authentication-Token"] = token;
  return Auth;
}

function getPlanetWarriorUrl() {
  return `${planetWarriorService.baseURL}`;
}

function getPlanetWarrior() {
  let Auth = axios.create(planetWarriorService);
  const planetWarriorToken = localStorage.getItem("planet_warrior_CCXT");

  if (planetWarriorToken)
    Auth.defaults.headers.common["X-Authentication-Token"] = planetWarriorToken;
  return Auth;
}

export const Auth = getAuth();
export const AuthURL = getAuthUrl();
export const PlanetWarrior = getPlanetWarrior();
export const PlanetWarriorURL = getPlanetWarriorUrl();