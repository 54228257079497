import { PlanetWarrior } from "../config/configUrl";
import { toast } from "react-toastify";

export const plasticFootprintCalculatorAction =
  (data, navigate, setLoading) => async (dispatch) => {
    dispatch({ type: "CALCULATING_DATA" });
    setLoading(true);
    PlanetWarrior.post("/carbon-footprint/create", data)
      .then((res) => {
        console.log(res, 'res');
        setLoading(false);
        navigate("/result");
        localStorage.setItem("result", JSON.stringify(res.data));
        dispatch({ type: "DATA_CALCULATED", payload: res.data });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response ? error.response?.data : error.message);

        dispatch({ type: "CANNOT_CALCULATE_DATA" });
      });
  };


// export const plasticFootprintCalculatorAction =
//   (data, navigate, setLoading) => async (dispatch) => {
//     console.log(data, 'redux');
//     dispatch({ type: "CALCULATING_DATA" });
//     setLoading(true);

//     try {
//       // Your API endpoint URL
//       const apiUrl = "https://staging.api.planet-warrior.thinktrash.co/carbon-footprint/create"; // Replace with your actual URL

//       // Make a POST request using fetch
//       const response = await fetch(apiUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       });

//       if (response.ok) {
//         const responseData = await response.json();

//         setLoading(false);
//         navigate("/result");
//         localStorage.setItem("result", JSON.stringify(responseData));
//         dispatch({ type: "DATA_CALCULATED", payload: responseData });
//       } else {
//         const errorData = await response.json();
//         setLoading(false);
//         toast.error(errorData.message || "Unable to calculate data");
//         dispatch({ type: "CANNOT_CALCULATE_DATA" });
//       }
//     } catch (error) {
//       setLoading(false);
//       toast.error(error.message || "Unable to calculate data");
//       dispatch({ type: "CANNOT_CALCULATE_DATA" });
//     }
//   };

