import React, { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children, ...props }) => {
  const [data, setData] = useState({
    household: {
        people: 0,
        renewableEnergyPercentage: 0,
        kerosene: 0,
        wood: 0,
        coconutShell: 0,
        lpgCylindersPerYear: 0,
    },
    lifestyle: {
      tripFrequency: "Select the Choices",
    },
    food: {
      eggFrequency: "Never",
      chickenFrequency: "Never",
      dairyFrequency: "Never",
      lambFrequency: "Never",
      beefFrequency: "Never",
      porkFrequency: "Never",
      fishFrequency: "Never",
      localSourcedPercentage: 0,
      unpackagedUnprocessedPercentage: 0,
    },
    travel: {
      motorcycle: {
          dailyDistance: 0,
          mileage: 0
      },
      car: {
          dailyDistance: 0,
          mileage: 0
      },
      weeklyDistances: {
          bus: 0,
          auto: 0,
          taxi: 0,
          train: 0,
          metro: 0
      },
      airTravel: {
          yearlyDistance: 0
      }
    },
    waste: {},
    waterUsage: {
      showerUsage: 0,
      faucetUsage: 0,
      toilet: {
        dailyFlushes: 0,
    },
    },
    planting: {}
  });

  // console.log(data, 'DataContext');

  return (
    <DataContext.Provider value={[data, setData]}>
        {React.cloneElement(children, props)}
    </DataContext.Provider>
  );
};
