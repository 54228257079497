import React from "react";
import household from "../utils/household.svg";
import lifestyle from "../utils/lifestyle.svg";
import food from "../utils/food.svg";
import travel from "../utils/travel.svg";
import waste from "../utils/waste.svg";

function Header({ currentStep, setCurrentStep }) {
  const pageTitles = [
    "Tell us more about your Household",
    "Tell us more about your Lifestyle",
    "Tell us more about your Food habit",
    "Tell us more about your travel",
    "Tell us more about your water usage and Waste Generated",
  ];

  return (
    <div className="header p-1">
      <div className="mt-2">
        {currentStep > 1 && (
          <span
            onClick={() => setCurrentStep((currentStep) => currentStep - 1)}
            className="bold hover"
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "1vw" }}
            >
              keyboard_backspace
            </span>
            Previous
          </span>
        )}
      </div>
      <div className="flex columns">
        <div className="flex justify-center gap-2" >
          {[household, lifestyle, food, travel, waste].map((image, index) => (
            <div
              key={index}
              className={
                index === currentStep - 1
                  ? "icon-current"
                  : index < currentStep - 1
                  ? "icon-active"
                  : "icon-circle"
              }
            >
              <img src={image} alt={`Icon ${index + 1}`} />
            </div>
          ))}
        </div>
        <h3 className="mt-2 center">{pageTitles[currentStep - 1]}</h3>
      </div>
    </div>
  );
}
export default Header;
