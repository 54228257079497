import React, { useContext, useState } from "react";
import { DataContext } from "../component/DataContext";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { plasticFootprintCalculatorAction } from "../redux/actions/calculator";
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from "react-toastify";

const Waste = ({ currentStep, setCurrentStep }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [data, setData] = useContext(DataContext);
    const [choice, setChoice] = useState('Select the Choices');
    const [choice2, setChoice2] = useState('Select the Choices');
    const [showChoices, setShowChoices] = useState(false);
    const [showChoices2, setShowChoices2] = useState(false);

    const [getWater, setGetWater] = useState(data.waterUsage.waterSource);
    const [aerators, setAerators] = useState(data.waterUsage.aerators);
    const [tletFlush, setTletFlush] = useState(data.waterUsage.toilet.flushSystem);
    const [recycleCompost, setRecycleCompost] = useState(data.waste.recycleCompost);
    const [recycledWasteTypes, setRecycledWasteTypes] = useState([]);

    const [error, setError] = useState({
        recycleCompost: "",
        recycledWasteTypes: "",
        waterSource: "",
        aerators: "",
        faucetUsage: "",
        showerUsage: "",
        toilet: {
            flushSystem: "",
            dailyFlushes: ""
        },
        washingFrequency: "",
        gardenWateringFrequency: ""
    });

    const [loading, setLoading] = useState(false);

    const handleNextClick = () => {
        // Dispatch the action to send the data to the backend
        // // // dispatch(plasticFootprintCalculatorAction(data, navigate, setLoading));
        handleValidation();
        if (!validate) {
            dispatch(plasticFootprintCalculatorAction(data, navigate, setLoading));
        } else {
            toast.error('Fill all the fields to proceed further');
        }
        // setCurrentStep(currentStep + 1);
        // navigate('/result');
    };

    const handleDataChange = (key, value) => {
        if (value < 0) return;
        setData(prevData => ({
          ...prevData,
          waterUsage: {
            ...prevData.waterUsage,
            [key]: parseInt(value)
          }
        }));
    };

    const handleDataChangev2 = (vehicle, property, value) => {
        if (value < 0) return;
        setData((prevData) => ({
          ...prevData,
          waterUsage: {
            ...prevData.waterUsage,
            [vehicle]: {
              ...prevData.waterUsage[vehicle], // Preserve the existing properties in the nested object
              [property]: parseInt(value), // Update the specific property
            },
          },
        }));
    };

    const handleDataChangev3 = (key, value) => {
        if (value < 0) return;
        setData(prevData => ({
          ...prevData,
          waste: {
            ...prevData.waste,
            [key]: parseInt(value)
          }
        }));
    };

    const handleGetWater = (value) =>{
      setGetWater(value);
    };

    const handleAerators = (value) =>{
      setAerators(value);
    };

    const handleTletFlush = (value) =>{
      setTletFlush(value);
    };

    const handleRecycleCompost = (value) =>{
      setRecycleCompost(value);
    };

    const handleRecycledWasteTypes = (item) =>{
        if (recycledWasteTypes.includes(item)) {
          setRecycledWasteTypes(recycledWasteTypes.filter((selectedItem) => selectedItem !== item));
        } else {
          setRecycledWasteTypes([...recycledWasteTypes, item]);
        }
    };

    const handleButtonClickV1 = (key, distance, data, setData) => {
      // Create a copy of the existing data
      const newData = { ...data };
    
      // Update the specified modeOfTravel distance in the waterUsage/waste object
      newData.waterUsage.toilet = {
        ...newData.waterUsage.toilet,
        [key]: distance,
      };
    
      // Set the updated data in the context
      setData(newData);
    }

    const handleButtonClickV2 = (objectPath, key, updatedValue, data, setData) => {
    // Create a copy of the existing data
    const newData = { ...data };
    
    // Update the specified key in the specified object
    let targetObject = newData;
    const pathSegments = objectPath.split('.');
    for (const segment of pathSegments) {
        targetObject = targetObject[segment];
    }
    
    targetObject[key] = updatedValue;
    
    // Set the updated data in the context
    setData(newData);
    };

    const handleDropDownClick = (key, updatedValue, data, setData) => {
    // Create a copy of the existing data
    const newData = { ...data };
    
    // Update the specified key in the waterUsage/waste object
    newData.waterUsage = {
        ...newData.waterUsage, // Preserve existing properties
        [key]: updatedValue, // Update the specified key with the updatedValue
    };
    
    // Set the updated data in the context
    setData(newData);
    };

    const handleCheckboxChange = (key, value) => {
      setData(prevData => {
        let updatedValue;
    
        // Check if the key already exists in the data
        if (prevData.waste[key]) {
          if (prevData.waste[key].includes(value)) {
            // Remove the value from the array
            updatedValue = prevData.waste[key].filter(item => item !== value);
          } else {
            // Add the value to the array
            updatedValue = [...prevData.waste[key], value];
          }
        } else {
          updatedValue = [value];
        }
    
        return {
          ...prevData,
          waste: {
            ...prevData.waste,
            [key]: updatedValue
          }
        };
      });

      // Toggle the selectedItems state based on the value of the clicked button
      setRecycledWasteTypes((prevSelectedItems) => {
        if (prevSelectedItems.includes(value)) {
          return prevSelectedItems.filter((item) => item !== value);
        } else {
          return [...prevSelectedItems, value];
        }
      });
    };

    const handleValidation = () =>{
      const newErrors = { ...error };

      if (data.waterUsage.waterSource === undefined) {
        newErrors.waterSource = "Select any one option";
      } else {
        newErrors.waterSource = ""; // Clear the error message if valid
      };

      if (data.waterUsage.aerators === undefined) {
        newErrors.aerators = "Select at least one option";
      } else {
        newErrors.aerators = ""; // Clear the error message if valid
      };

      if (data.waterUsage.showerUsage < 1) {
        newErrors.showerUsage = "Mins must be at least 1";
      } else {
        newErrors.showerUsage = ""; // Clear the error message if valid
      };

      if (data.waterUsage.toilet.flushSystem === undefined) {
        newErrors.toilet.flushSystem = "Select at least one option";
      } else {
        newErrors.toilet.flushSystem = ""; // Clear the error message if valid
      };

      if (data.waterUsage.washingFrequency === undefined) {
        newErrors.washingFrequency = "Select any option before submitting";
      } else {
        newErrors.washingFrequency = ""; // Clear the error message if valid
      };

      if (data.waste.recycleCompost === undefined) {
        newErrors.recycleCompost = "Select at least one option";
      } else {
        newErrors.recycleCompost = ""; // Clear the error message if valid
      };

      if (data.waterUsage.toilet.dailyFlushes < 1) {
        newErrors.toilet.dailyFlushes = "Must be at least 1";
      } else {
        newErrors.toilet.dailyFlushes = ""; // Clear the error message if valid
      };

      if (data.waterUsage.faucetUsage < 1) {
        newErrors.faucetUsage = "Must be at least 1";
      } else {
        newErrors.faucetUsage = ""; // Clear the error message if valid
      };

      if (data.waterUsage.gardenWateringFrequency === undefined) {
        newErrors.gardenWateringFrequency = "Select any option before submitting";
      } else {
        newErrors.gardenWateringFrequency = ""; // Clear the error message if valid
      };

      if (data.waste.recycledWasteTypes === undefined) {
        newErrors.recycledWasteTypes = "Select at least one option";
      } else {
        newErrors.recycledWasteTypes = ""; // Clear the error message if valid
      };
    // Set the new errors object in state
    setError(newErrors);
    };

    const validate = data.waterUsage.waterSource === undefined || data.waterUsage.aerators === undefined ||
    data.waterUsage.showerUsage < 1 || data.waterUsage.toilet.flushSystem === undefined ||
    data.waterUsage.washingFrequency === undefined || data.waste.recycleCompost === undefined ||
    data.waterUsage.toilet.dailyFlushes < 1 || data.waterUsage.faucetUsage < 1 || data.waterUsage.gardenWateringFrequency === undefined ||
    data.waste.recycledWasteTypes === undefined;

  return (
    <div className="mt-4">
        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            1. Where do you get your water from?
            <span className="mandatory-field">*</span>
            </h3>
            <div className="mt-1 flex gap-2">
            {[
                { value: "Municipal Water Supply", text: "Municipal Water" },
                { value: "Borewell", text: "Borewell" },
                { value: "Both", text: "Both" },
            ].map((option, index) => (
                <div
                key={index}
                className={
                    getWater === option.value
                    ? "buttonsv1-selected-container"
                    : "buttonsv1-container"
                }
                >
                <button
                    className="btn black-text transparent z-depth-0"
                    onClick={() =>{
                    handleButtonClickV2("waterUsage", "waterSource", option.value, data, setData);
                    handleGetWater(option.value)
                    }}
                >
                    {option.text}
                </button>
                </div>
            ))}
            </div>
            <p className="red-text">{error?.waterSource}</p>
        </div>
        
        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            2. Is the water systems in your home with aerators?
            <span className="mandatory-field">*</span>
            </h3>
            <div className="mt-1 flex gap-2">
            {[
                { value: "Yes", text: "👍Yes" },
                { value: "No", text: "👎No" },
            ].map((option, index) => (
                <div
                key={index}
                className={
                    aerators === option.value
                    ? "buttonsv1-selected-container"
                    : "buttonsv1-container"
                }
                >
                <button
                    className="btn black-text transparent z-depth-0"
                    onClick={() =>{
                    handleButtonClickV2("waterUsage", "aerators", option.value, data, setData);
                    handleAerators(option.value)
                    }}
                >
                    {option.text}
                </button>
                </div>
            ))}
            </div>
            <p className="red-text">{error?.aerators}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            3. How long do you use shower in a day? (mins/​day)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("showerUsage", data.waterUsage.showerUsage - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.waterUsage.showerUsage}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChange("showerUsage", data.waterUsage.showerUsage + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.showerUsage}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            4. What type is your toilet flush system?
            <span className="mandatory-field">*</span>
            </h3>
            <div className="mt-1 flex gap-2">
            {[
                { value: "Single", text: "Single" },
                { value: "Dual", text: "Dual" },
            ].map((option, index) => (
                <div
                key={index}
                className={
                    tletFlush === option.value
                    ? "buttonsv1-selected-container"
                    : "buttonsv1-container"
                }
                >
                <button
                    className="btn black-text transparent z-depth-0"
                    onClick={() =>{
                    handleButtonClickV1("flushSystem", option.value, data, setData);
                    handleTletFlush(option.value)
                    }}
                >
                    {option.text}
                </button>
                </div>
            ))}
            </div>
            <p className="red-text">{error?.toilet?.flushSystem}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
                5. How often do you wash? (Clothes, Car etc.,)
                <span className="mandatory-field">*</span>
            </h3>

            <button 
                onClick={()=>{setShowChoices(!showChoices)}}
                className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"}
            >
                <p style={{ color: choice === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice}</p>
                <span class="material-symbols-outlined">stat_minus_1</span>
            </button >

            {showChoices ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"}>
            {[
                { value: "Everyday", text: "Everyday" },
                { value: "Weekly twice/thrice", text: "Weekly twice/thrice" },
                { value: "Weekly once", text: "Weekly once" },
                { value: "Once in two weeks", text: "Once in two weeks" },
                { value: "Once in a month", text: "Once in a month" },
            ].map((option, index) => (
                <p key={index} onClick={()=>{
                    handleDropDownClick("washingFrequency", option.value, data, setData); setChoice(option?.value); setShowChoices(false)}} >{option?.text}</p>
            ))}
            </div> : null}
            <p className="red-text">{error?.washingFrequency}</p>
        </div>
        
        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            6. Do you recycle/compost the waste generated in your place?
            <span className="mandatory-field">*</span>
            </h3>
            <div className="mt-1 flex gap-2">
            {[
                { value: "Yes", text: "👍Yes" },
                { value: "No", text: "👎No" },
            ].map((option, index) => (
                <div
                key={index}
                className={
                    recycleCompost === option.value
                    ? "buttonsv1-selected-container"
                    : "buttonsv1-container"
                }
                >
                <button
                    className="btn black-text transparent z-depth-0"
                    onClick={() =>{
                    handleButtonClickV2("waste", "recycleCompost", option.value, data, setData);
                    handleRecycleCompost(option.value)
                    }}
                >
                    {option.text}
                </button>
                </div>
            ))}
            </div>
            <p className="red-text">{error?.recycleCompost}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            7. How many times do you flush your toilet in a day?
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChangev2("toilet", "dailyFlushes", data.waterUsage.toilet.dailyFlushes - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.waterUsage.toilet.dailyFlushes}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                handleDataChangev2("toilet", "dailyFlushes", data.waterUsage.toilet.dailyFlushes + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.toilet?.dailyFlushes}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
            8. How long do you use faucet in a day? (mins/​day)
            <span className="mandatory-field">*</span>
            </h3>

            <div
            className="valign-wrapper mt-1 counter-buttons-container"
            style={{
                width: isMobile ? "80vw" : "10vw",
            }}
            >
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                    handleDataChange("faucetUsage", data.waterUsage.faucetUsage - 1)
                }
            >
                <span className="material-symbols-outlined black-text">remove</span>
            </button>
            <span
                className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
            >
                {data.waterUsage.faucetUsage}
            </span>
            <button
                className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
                onClick={() =>
                    handleDataChange("faucetUsage", data.waterUsage.faucetUsage + 1)
                }
            >
                <span className="material-symbols-outlined black-text">add</span>
            </button>
            </div>
            <p className="red-text">{error?.faucetUsage}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
                9. How often do you water your garden?
                <span className="mandatory-field">*</span>
            </h3>

            <button 
                onClick={()=>{setShowChoices2(!showChoices2)}} 
                className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"}
            >
                <p style={{ color: choice2 === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice2}</p>
                <span class="material-symbols-outlined">stat_minus_1</span>
            </button >

            {showChoices2 ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"}>
            {[
                { value: "Everyday", text: "Everyday" },
                { value: "Weekly twice/thrice", text: "Weekly twice/thrice" },
                { value: "Weekly once", text: "Weekly once" },
                { value: "Once in two weeks", text: "Once in two weeks" },
                { value: "Once in a month", text: "Once in a month" },
            ].map((option, index) => (
                <p key={index} onClick={()=>{
                    handleDropDownClick("gardenWateringFrequency", option.value, data, setData); setChoice2(option?.value); setShowChoices2(false)}} >{option?.text}</p>
            ))}
            </div> : null}
            <p className="red-text">{error?.gardenWateringFrequency}</p>
        </div>

        <div className="mt-2">
            <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
                10. Which of these types of waste dod you recycle and/or compost?
                <span className="mandatory-field">*</span>
            </h3>

            <div className="mt-1 flex gap-2">
                {[
                    { value: "Food/Organic Waste", text: "Food/Organic Waste" },
                    { value: "Tin Cans", text: "Tin Cans" },
                    { value: "Glass", text: "Glass" },
                    { value: "Paper", text: "Paper" },
                    { value: "Plastic", text: "Plastic" },
                ].map((option, index) => (
                    <div key={index} className={recycledWasteTypes.includes(option.value) ? "buttonsv1-selected-container" : "buttonsv1-container"}>
                    <button
                    className="btn black-text transparent z-depth-0"
                    value={option?.value}
                    name="recycledWasteTypes"
                    onClick={() => {handleCheckboxChange("recycledWasteTypes", option.value); handleRecycledWasteTypes(option?.value)}}
                    >
                    {option?.text}
                    </button>
                    </div>
                ))}
            </div>
            <p className="red-text">{error?.recycledWasteTypes}</p>
        </div>
    
    <div className="center mb-3 mt-2">
        <button 
        className="btn"
        onClick={handleNextClick}
        >
        {loading ? 'loading..' : 'Submit'}
        {loading ? null : <span class="material-symbols-outlined arrow-icon-next">arrow_right_alt</span>}
        </button>
    </div>

    </div>
  );
};

export default Waste;