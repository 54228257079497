import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../component/DataContext";
import { useMediaQuery } from 'react-responsive';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

// Define a key for local storage
const LOCAL_STORAGE_KEY = "food";

const FrequencyOptions = [
  { value: "Never", text: "Never" },
  { value: "Infrequently - Once in few weeks", text: "Infrequently - Once in few weeks" },
  { value: "Occasionally - Once/twice a week", text: "Occasionally - Once/twice a week" },
  { value: "Often - Nearly every day", text: "Often - Nearly every day" },
  { value: "Very Often - Nearly every meal", text: "Very Often - Nearly every meal" },
];

const Food = ({ currentStep, setCurrentStep }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [data, setData] = useContext(DataContext);
    // console.log(data, 'food');

    const [choice, setChoice] = useState(data?.food?.eggFrequency);
    const [showChoices, setShowChoices] = useState(false);
    const [choice1, setChoice1] = useState(data?.food?.chickenFrequency);
    const [showChoices1, setShowChoices1] = useState(false);
    const [choice2, setChoice2] = useState(data?.food?.lambFrequency);
    const [showChoices2, setShowChoices2] = useState(false);
    const [choice3, setChoice3] = useState(data?.food?.porkFrequency);
    const [showChoices3, setShowChoices3] = useState(false);
    const [choice4, setChoice4] = useState(data?.food?.beefFrequency);
    const [showChoices4, setShowChoices4] = useState(false);
    const [choice5, setChoice5] = useState(data?.food?.fishFrequency);
    const [showChoices5, setShowChoices5] = useState(false);
    const [choice6, setChoice6] = useState(data?.food?.dairyFrequency);
    const [showChoices6, setShowChoices6] = useState(false);

    const [products, setProducts] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || []);
    const [showComponent1, setShowComponent1] = useState(false);
    const [showComponent2, setShowComponent2] = useState(false);
    const [showComponent3, setShowComponent3] = useState(false);
    const [showComponent4, setShowComponent4] = useState(false);
    const [showComponent5, setShowComponent5] = useState(false);
    const [showComponent6, setShowComponent6] = useState(false);
    const [showComponent7, setShowComponent7] = useState(false);

    console.log(showComponent1, showComponent2, showComponent3, showComponent4, showComponent5, showComponent6, showComponent7);

    const [error, setError] = useState({
      animalBasedProducts: "",
      eggFrequency: "",
      chickenFrequency: "",
      dairyFrequency: "",
      lambFrequency: "",
      beefFrequency: "",
      porkFrequency: "",
      fishFrequency: "",
      localSourcedPercentage: "",
      unpackagedUnprocessedPercentage: ""
  });

    const handleNextClick = () => {
        // setCurrentStep(currentStep + 1);
        handleValidation();
        if (!validate) {
          setCurrentStep(currentStep + 1);
        } else {
          toast.error('Fill all the fields to proceed further');
        }
      };

    const handleDataChange = (key, value) => {
        if (value < 0) return;
        setData(prevData => ({
          ...prevData,
          food: {
            ...prevData.food,
            [key]: parseInt(value)
          }
        }));
    };

    const handleProducts = (value) =>{
      return null;
    };

    // const handleCheckboxChange = (key, value) => {
    //   setData(prevData => {
    //     let updatedValue;
    
    //     // Check if the key already exists in the data
    //     if (prevData.food[key]) {
    //       if (prevData.food[key].includes(value)) {
    //         // Remove the value from the array
    //         updatedValue = prevData.food[key].filter(item => item !== value);
    //       } else {
    //         // Add the value to the array
    //         updatedValue = [...prevData.food[key], value];
    //       }
    //     } else {
    //       updatedValue = [value];
    //     }
    
    //     return {
    //       ...prevData,
    //       food: {
    //         ...prevData.food,
    //         [key]: updatedValue
    //       }
    //     };
    //   });

    //   // Toggle the selectedItems state based on the value of the clicked button
    //   setProducts((prevSelectedItems) => {
    //     if (prevSelectedItems.includes(value)) {
    //       return prevSelectedItems.filter((item) => item !== value);
    //     } else {
    //       return [...prevSelectedItems, value];
    //     }
    //   });
    // };

    const handleCheckboxChange = (key, value) => {
      setData((prevData) => {
        const updatedValue = [...(prevData.food[key] || [])];
  
        if (value === 'None') {
          return {
            ...prevData,
            food: {
              ...prevData.food,
              [key]: ['None'],
            },
          };
        }
  
        if (updatedValue.includes('None')) {
          updatedValue.splice(updatedValue.indexOf('None'), 1);
        }
  
        if (updatedValue.includes(value)) {
          updatedValue.splice(updatedValue.indexOf(value), 1);
        } else {
          updatedValue.push(value);
        }
  
        return {
          ...prevData,
          food: {
            ...prevData.food,
            [key]: updatedValue,
          },
        };
      });
    
      // Toggle the selectedItems state based on the value of the clicked button
      setProducts((prevSelectedItems) => {
        if (value === 'None') {
          // If 'None' is selected, clear all other selections
          return [value];
        } else if (prevSelectedItems.includes('None')) {
          // If 'None' was previously selected, remove it from the array
          return prevSelectedItems.filter((item) => item !== 'None');
        } else if (prevSelectedItems.includes(value)) {
          return prevSelectedItems.filter((item) => item !== value);
        } else {
          return [...prevSelectedItems, value];
        }
      });
    };

    const handleButtonClick = (key, updatedValue, data, setData) => {
      // Create a copy of the existing data
      const newData = { ...data };
    
      // Update the specified key in the food object
      newData.food = {
        ...newData.food, // Preserve existing properties
        [key]: updatedValue, // Update the specified key with the updatedValue
      };
    
      // Set the updated data in the context
      setData(newData);
    };

    const handleValidation = () =>{
      const newErrors = { ...error };

      if (data.food.animalBasedProducts === undefined) {
        newErrors.animalBasedProducts = "Select any one product at least and select it's option";
      } else {
        newErrors.animalBasedProducts = ""; // Clear the error message if valid
      };

      if (data.food.localSourcedPercentage < 1) {
        newErrors.localSourcedPercentage = "Percentage must be at least 1";
      } else {
        newErrors.localSourcedPercentage = ""; // Clear the error message if valid
      };

      if (data.food.unpackagedUnprocessedPercentage < 1) {
        newErrors.unpackagedUnprocessedPercentage = "Percentage must be at least 1";
      } else {
        newErrors.unpackagedUnprocessedPercentage = ""; // Clear the error message if valid
      };
    // Set the new errors object in state
    setError(newErrors);
    };

    const validate = data.food.animalBasedProducts === undefined || 
    data.food.localSourcedPercentage < 1 || data.food.unpackagedUnprocessedPercentage < 1;

    // Update local storage whenever the products state changes
    useEffect(() => {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(products));
    }, [products]);

  return (
    <div className="mt-4">
    <div>
    <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
      1. Choose the animal-based products you consume?
      <span className="mandatory-field">*</span>
    </h3>

    <div className="mt-1 flex gap-2">
          {[
            { value: "Chicken", text: "🐔 Chicken" },
            { value: "Lamb", text: "🐑 Lamb" },
            { value: "Beef", text: "🐮 Beef" },
          ].map((option, index) => (
            <div key={index} className={products.includes(option.value) ? "buttonsv1-selected-container" : "buttonsv1-container"}>
            <button
              className="btn black-text transparent z-depth-0"
              value={option?.value}
              name="animalBasedProducts"
              onClick={() => {handleCheckboxChange("animalBasedProducts", option.value); handleProducts(option?.value)}}
            >
              {option?.text}
            </button>
            </div>
          ))}
    </div>

    <div className="mt-1 flex gap-2">
          {[
            { value: "Pork", text: "🐷 Pork" },
            { value: "Egg", text: "🍳 Egg" },
            { value: "Fish", text: "🐟 Fish" },
          ].map((option, index) => (
            <div key={index} className={products.includes(option.value) ? "buttonsv1-selected-container" : "buttonsv1-container"}>
            <button
              className="btn black-text transparent z-depth-0"
              value={option?.value}
              name="animalBasedProducts"
              onClick={() => {handleCheckboxChange("animalBasedProducts", option.value); handleProducts(option?.value)}}
            >
              {option?.text}
            </button>
            </div>
          ))}
    </div>

    <div className="mt-1 flex gap-2">
          {[
            { value: "Dairy based products", text: "🥛 Dairy based products" },
            { value: "None", text: "🚫 None" },
          ].map((option, index) => (
            <div key={index} className={products.includes(option.value) ? "buttonsv1-selected-container" : "buttonsv1-container"}>
            <button
              className="btn black-text transparent z-depth-0"
              value={option?.value}
              name="animalBasedProducts"
              onClick={() => {handleCheckboxChange("animalBasedProducts", option.value); handleProducts(option?.value)}}
            >
              {option?.text}
            </button>
            </div>
          ))}
    </div>
            <p className="red-text">{error?.animalBasedProducts}</p>

    <div className={"mt-2"}>
      {products.includes("Chicken") && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          I. How often do you eat Chicken?
          <span className="mandatory-field">*</span>
        </h3>

        <button 
          onClick={()=>{setShowChoices1(!showChoices1)}} 
          className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"} 
          style={{  }}
        >
          <p style={{ color: choice1 === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice1}</p>
          <span class="material-symbols-outlined">stat_minus_1</span>
        </button >

        {showChoices1 ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"} style={{  }}>
          {FrequencyOptions.map((option, index) => (
            <p key={index} onClick={()=>{
              handleButtonClick("chickenFrequency", option.value, data, setData); setChoice1(option?.value); setShowChoices1(false)}} >{option?.text}</p>
          ))}
        </div> : null}
      </div>}

      {products.includes("Lamb") && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          II. How often do you eat Lamb?
          <span className="mandatory-field">*</span>
        </h3>

        <button 
          onClick={()=>{setShowChoices2(!showChoices2)}} 
          className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"}
          style={{ }}
        >
          <p style={{ color: choice2 === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice2}</p>
          <span class="material-symbols-outlined">stat_minus_1</span>
        </button >

        {showChoices2 ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"} style={{ }}>
          {FrequencyOptions.map((option, index) => (
            <p key={index} onClick={()=>{
              handleButtonClick("lambFrequency", option.value, data, setData); setChoice2(option?.value); setShowChoices2(false)}} >{option?.text}</p>
          ))}
        </div> : null}
      </div>}

      {products.includes("Beef") && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          III. How often do you eat Beef?
          <span className="mandatory-field">*</span>
        </h3>

        <button 
          onClick={()=>{setShowChoices4(!showChoices4)}} 
          className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"} 
          style={{  }}
        >
          <p style={{ color: choice4 === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice4}</p>
          <span class="material-symbols-outlined">stat_minus_1</span>
        </button >

        {showChoices4 ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"} style={{  }}>
          {FrequencyOptions.map((option, index) => (
            <p key={index} onClick={()=>{
              handleButtonClick("beefFrequency", option.value, data, setData); setChoice4(option?.value); setShowChoices4(false)}} >{option?.text}</p>
          ))}
        </div> : null}
      </div>}

      {products.includes("Pork") && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          IV. How often do you eat Pork?
          <span className="mandatory-field">*</span>
        </h3>

        <button 
          onClick={()=>{setShowChoices3(!showChoices3)}} 
          className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"} 
          style={{ paddingLeft: '1rem', paddingRight: '0.5rem',  }}
        >
          <p style={{ color: choice3 === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice3}</p>
          <span class="material-symbols-outlined">stat_minus_1</span>
        </button >

        {showChoices3 ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"} style={{  }}>
          {FrequencyOptions.map((option, index) => (
            <p key={index} onClick={()=>{
              handleButtonClick("porkFrequency", option.value, data, setData); setChoice3(option?.value); setShowChoices3(false)}} >{option?.text}</p>
          ))}
        </div> : null}
      </div>}

      {products.includes("Egg") && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          V. How often do you eat Egg?
          <span className="mandatory-field">*</span>
        </h3>

        <button 
          onClick={()=>{setShowChoices(!showChoices)}} 
          className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"} 
          style={{  }}
        >
          <p style={{ color: choice === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice}</p>
          <span class="material-symbols-outlined">stat_minus_1</span>
        </button >

        {showChoices ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"} style={{  }}>
          {FrequencyOptions.map((option, index) => (
            <p key={index} onClick={()=>{
              handleButtonClick("eggFrequency", option.value, data, setData); setChoice(option?.value); setShowChoices(false)}} >{option?.text}</p>
          ))}
        </div> : null}
      </div>}
    </div>

    <div className={"mt-2"}>
      {products.includes("Fish") && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          VI. How often do you eat Fish?
          <span className="mandatory-field">*</span>
        </h3>

        <button 
          onClick={()=>{setShowChoices5(!showChoices5)}}
          className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"} 
          style={{  }}
        >
          <p style={{ color: choice5 === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice5}</p>
          <span class="material-symbols-outlined">stat_minus_1</span>
        </button >

        {showChoices5 ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"} style={{  }}>
          {FrequencyOptions.map((option, index) => (
            <p key={index} onClick={()=>{
              handleButtonClick("fishFrequency", option.value, data, setData); setChoice5(option?.value); setShowChoices5(false)}} >{option?.text}</p>
          ))}
        </div> : null}
      </div>}
    </div>

      {products.includes("Dairy based products") && <div className="mt-2">
        <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
          VII. How often do you eat Dairy based products? (Like milk, curd, butter, ghee, cheese, paneer etc.,)
          <span className="mandatory-field">*</span>
        </h3>

        <button 
          onClick={()=>{setShowChoices6(!showChoices6)}} 
          className={isMobile ? "mt-1 custom-dropdown-ismobile flex align-center space-between pl-1" : "mt-1 custom-dropdown flex align-center space-between pl-1"} 
          style={{  }}
        >
          <p style={{ color: choice6 === 'Select the Choices' ? '#C8C8C8' : 'black' }}>{choice6}</p>
          <span class="material-symbols-outlined">stat_minus_1</span>
        </button >

        {showChoices6 ? <div className={isMobile ? "custom-dropdown-option-ismobile mt-1" : "custom-dropdown-option mt-1"} style={{  }}>
            {FrequencyOptions.map((option, index) => (
              <p key={index} onClick={()=>{
                handleButtonClick("dairyFrequency", option.value, data, setData); setChoice6(option?.value); setShowChoices6(false)}} >{option?.text}</p>
            ))}
        </div> : null}
      </div>}
    
    </div>

<div className="mt-2">
  <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
  2. How much % of the food you eat is fresh and locally sourced?
  <span className="mandatory-field">*</span>
  </h3>

  <div
    className="valign-wrapper mt-1 counter-buttons-container"
    style={{
      width: isMobile ? "80vw" : "10vw",
    }}
  >
    <button
        className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
      onClick={() =>
        handleDataChange("localSourcedPercentage", data.food.localSourcedPercentage - 1)
      }
    >
      <span className="material-symbols-outlined black-text">remove</span>
    </button>
    <span
        className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
    >
      {data.food.localSourcedPercentage}
    </span>
    <button
        className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
      onClick={() =>
        handleDataChange("localSourcedPercentage", data.food.localSourcedPercentage + 1)
      }
    >
      <span className="material-symbols-outlined black-text">add</span>
    </button>
  </div>
            <p className="red-text">{error?.localSourcedPercentage}</p>
</div>

<div className="mt-2">
  <h3 className={isMobile ? "responsive-heading-mobile" : ""}>
  3. How much % of the food you eat is Unpackaged and Unprocessed?
  <span className="mandatory-field">*</span>
  </h3>

  <div
    className="valign-wrapper mt-1 counter-buttons-container"
    style={{
      width: isMobile ? "80vw" : "10vw",
    }}
  >
    <button
        className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
      onClick={() =>
        handleDataChange("unpackagedUnprocessedPercentage", data.food.unpackagedUnprocessedPercentage - 1)
      }
    >
      <span className="material-symbols-outlined black-text">remove</span>
    </button>
    <span
        className={isMobile ? "center-align counter-font-ismobile" : "center-align counter-font"}
    >
      {data.food.unpackagedUnprocessedPercentage}
    </span>
    <button
        className={isMobile ? "btn counter-buttons-ismobile" : "btn counter-buttons"}
      onClick={() =>
        handleDataChange("unpackagedUnprocessedPercentage", data.food.unpackagedUnprocessedPercentage + 1)
      }
    >
      <span className="material-symbols-outlined black-text">add</span>
    </button>
  </div>
            <p className="red-text">{error?.unpackagedUnprocessedPercentage}</p>
</div>
    
    <div className="center mb-3 mt-2">
        <button 
        className="buttonsv2-container" 
        onClick={handleNextClick}
        >
        Next
        <span class="material-symbols-outlined arrow-icon-next">arrow_right_alt</span>
        </button>
    </div>
    </div>
  );
};

export default Food;