export const loadingReducer = (
    state = {showLoading:false},
    action) => {
    switch(action.type){
        case "SHOW_LOADING":
            return{ showLoading:true }
        case "HIDE_LOADING":
            return {showLoading:false}
        default:
            return state
    }
};