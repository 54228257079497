import { PlanetWarrior } from "../config/configUrl";
import { toast } from "react-toastify";

export const sendEmailAction = (data, setLoading) => async (dispatch) => {
    dispatch({ type: "SENDING_EMAIL" });
    setLoading(true);
    
    PlanetWarrior.post("/send-email", data)
      .then((res) => {
        setLoading(false);
        toast.success("Email sent successfully!");
        dispatch({ type: "EMAIL_SENT", payload: res.data });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response ? error.response?.data : error.message);
        dispatch({ type: "EMAIL_SENDING_FAILED" });
      });
};