import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadingReducer } from "../reducers/loadingReducer";
import { calculaterReducer } from "../reducers/calculatorReducer";
import { emailSendingReducer } from "../reducers/emailReducer";

const reducer = combineReducers({
  loading: loadingReducer,
  calculater: calculaterReducer,
  emailSending: emailSendingReducer
});

const initialstate = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialstate,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
